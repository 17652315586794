import sendRequest from "@/libs/sendRequest"
import { formatAmount } from "@/composables/formatAmount"
import { decodeUtf8 } from "@/composables/utils"
//import AES from '@/libs/AES'

export const sales = {
    namespaced: true,
    state: {
        families: [],
        banners:[],
        categories: [],
        offers: [],
        brands: [],
        favorites: [],
        products: [],
        product: {},
        order_history: [],
        order: null,
        cart_products: [],
        order_reference: null,
        payment_url: null,
        token_found: false
    },
    getters: {

        getFamilies: (state) => {
            let families = sessionStorage.getItem('wiks@families') ? JSON.parse(sessionStorage.getItem('wiks@families')) : []
            state.families = families
            return state.families
        },
        getBanners: (state) => {
            let banners = sessionStorage.getItem('wiks@banners') ? JSON.parse(sessionStorage.getItem('wiks@banners')) : []
            state.banners = banners
            return state.banners
        },
        getCategories: (state) => {
            let categories = sessionStorage.getItem('wiks@categories') ? JSON.parse(sessionStorage.getItem('wiks@categories')) : []
            state.categories = categories
            return state.categories
        },
        getOffers: (state) => {

            let offers =  sessionStorage.getItem('wiks@offers') ? JSON.parse(sessionStorage.getItem('wiks@offers')) : []
            state.offers = offers
            return state.offers
        },
        getBrands: (state) => {
            let brands = sessionStorage.getItem('wiks@brands') ? JSON.parse(sessionStorage.getItem('wiks@brands')) : []
            state.brands = brands
            return state.brands
        },
        getFavorites: (state) => {
            let favorites = sessionStorage.getItem('wiks@favorites') ? JSON.parse(sessionStorage.getItem('wiks@favorites')) : []
            
            if(favorites.length > 0) {
                for(let i in favorites) {
                    favorites[i].product_name = decodeUtf8(favorites[i].product_name)
                }
            }
            

            state.favorites = favorites
            return state.favorites
        },
        getProducts: (state) => (state.products),
        getProduct: (state) => (state.product),
        getOrderHistory: (state) => (state.order_history),
        getOrder: (state) => {
            let order = sessionStorage.getItem('wiks@orderdata') ? JSON.parse(sessionStorage.getItem('wiks@orderdata')) : null
            state.order = order
            return state.order
        },
        getCartProducts: (state) => {
            state.cart_products = JSON.parse(sessionStorage.getItem('wiks@cart')) || [] ;
            return state.cart_products
        },
        getReference: (state) => {
            let reference = sessionStorage.getItem('wiks@reference') ? sessionStorage.getItem('wiks@reference') : null
            state.order_reference = reference
            return state.order_reference
        },
        getPaymentUrl: (state) => {
            return state.payment_url
        },
        getTokenFound: (state) => (state.token_found),
    },
    mutations: {

        setFamilies: (state, payload) => {
            state.families = payload
            sessionStorage.setItem('wiks@families', JSON.stringify(payload))
        },
        setBanners: (state, payload) => {
            state.banners = payload
            sessionStorage.setItem('wiks@banners', JSON.stringify(payload))
        },
        setCategories: (state, payload) => {
            state.categories = payload
            sessionStorage.setItem('wiks@categories', JSON.stringify(payload))
        },
        setOffers: (state, payload) => {
            state.offers = payload
            sessionStorage.setItem('wiks@offers', JSON.stringify(payload))
        },
        setBrands: (state, payload) => {
            state.brands = payload
            sessionStorage.setItem('wiks@brands', JSON.stringify(payload))
        },
        setFavorites: (state, payload) => {
            state.favorites = payload
            sessionStorage.setItem('wiks@favorites', JSON.stringify(payload))
        },


        setProducts: (state, payload) => state.products = payload,
        setProduct: (state, payload) => state.product = payload,
        setOrderHistory: (state, payload) => state.order_history = payload,
        setOrder: (state, payload) => {
            sessionStorage.setItem('wiks@orderdata', JSON.stringify(payload))
            state.order = payload
        },

        setCartProducts: (state, payload) => {
            sessionStorage.setItem('wiks@cart', JSON.stringify(payload))
            state.cart_products = payload
        },
        setReference: (state, payload) => {
            if (!payload) {
                sessionStorage.removeItem('wiks@reference')
                state.order_reference = payload
            } else {
                sessionStorage.setItem('wiks@reference', payload)
                state.order_reference = payload
            }
        },
        setTokenFound: (state, payload) => state.token_found = payload
    },
    actions: {
        listFamily: async ({ commit, rootGetters}) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('websales/product/family','GET', null, null, null, custom_headers)
            
            if(response.success && response.data) {
                commit('setFamilies', response.data.family ?? [])
            }

            else if(!response.success && response.errors) {
                console.log('errors:', response.errors)
            }

            else if(response.success && !response.data) {
                console.log('Se ha perdido la conexión con la API')
            }   
        },
        listBanners: async ({ commit, rootGetters}, query ) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('websales/product/banners/section','GET', null, null, query, custom_headers)
            
            if(response.success && response.data) {
                commit('setBanners', response.data ?? [])
            }

            else if(!response.success && response.errors) {
                console.log('errors:', response.errors)
            }

            else if(response.success && !response.data) {
                console.log('Se ha perdido la conexión con la API')
            }   
        },
        listOffers: async ({ commit, rootGetters }) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('websales/product/promos','GET', null, null, null, custom_headers)
           
            if(response.success && response.data) {
                commit('setOffers', response.data.promotion ?? [])
            }

            else if(!response.success && response.errors) {
                console.log('errors:', response.errors)
            }

            else if(response.success && !response.data) {
                console.log('Se ha perdido la conexión con la API')
            }   
        },
        listBrands: async({ commit, rootGetters }) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('websales/product/categories','GET', null, null, null, custom_headers)
            
            if(response.success && response.data) {
                commit('setBrands', response.data.categories ?? [])
            }
            else if(!response.success && response.errors) {
                console.log('errors:', response.errors)
            }

            else if(response.success && !response.data) {
                console.log('Se ha perdido la conexión con la API')
            }   
        },
        listCategories: async({ commit, rootGetters }, query) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('websales/product/categories','GET', null, null, query, custom_headers)
            //console.log(response)
            if(response.success && response.data) {
                commit('setBrands', response.data.categories ?? [])
                console.log(response.data)
            }
            else if(!response.success && response.errors) {
                console.log('errors:', response.errors)
            }

            else if(response.success && !response.data) {
                console.log('Se ha perdido la conexión con la API')
            }   
        },
        listFavorites: async({ commit, rootGetters, dispatch, getters }) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('websales/product/bookmark','GET', null, null, null, custom_headers)
            
            if(response.success && response.data) {
                let favorites = []
                for await (let bookmark of response.data.bookmark ) {
                    await dispatch('viewProduct', { code: bookmark.Producto_id})

                    let product = getters.getProduct

                    if(product) {
                        product.picture = `https://api.wiks-services.online/administrator/products/resources?id=${product.product_code}`
                        favorites.push({ bookmark: true, ...product})
                    }
                }

                commit('setFavorites', favorites)
            }
            else if(!response.success && response.errors) {
                console.log('errors:', response.errors)
            }

            else if(response.success && !response.data) {
                console.log('Se ha perdido la conexión con la API')
            }
        },
        viewProduct: async({ commit, rootGetters }, code) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('websales/product/detail', 'GET', null, null, code, custom_headers)

            if(response.success && response.data) {
                commit('setProduct', response.data.product)
            }

            else if(!response.success && response.errors) {
                console.log('errors:', response.errors)
                commit('setProduct', null)
            }

            else if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        listProductsByCategory: async({ commit, rootGetters }, query) => {

            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('websales/product/category', 'GET', null, null, query, custom_headers)

            if(response.success && response.data) {
                commit('setProducts', response.data.product ?? [])
            }

            else if(!response.success && response.errors) {
                console.log(response.errors)
            }

            else if(response.success && !response.data) {
                console.log('Se ha perdido la conexion con la API')
            }

        },
        listOrderHistory: async({commit, rootGetters}) => {
            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('websales/history/orders', 'GET', null, null, null, custom_headers)

            if(response.success && response.data) {
                commit('setOrderHistory', response.data.history ?? []) 
            }
        },
        addToShoppingCart: async ({ commit, getters, rootGetters, dispatch }, new_product) => {

            const reference = getters.getReference;
    
            const products = getters.getCartProducts.map((product) => {
                return {
                    code: product.code,
                    qty: product.qty,
                    total: formatAmount(Number(product.qty) * Number(product.price), 2),
                    promo: false,
                    name: product.name
                }
            })


            if(reference != null || reference!= undefined) {
                //update order
                let index = products.findIndex( (product) => product.code == new_product.code)

                if(index >= 0) {
                    products[index].qty = new_product.qty
                }

                else {
                    products.push(new_product)
                }


                let total = 0;
                for(let product in products) {
                    total = formatAmount(Number(total) + Number(products[product].total))
                }

                const body = {
                    product: products,
                    total: total
                }

                const query = {
                    reference : reference
                }
                

                const custom_headers = rootGetters.getHeaders;

                const response = await sendRequest('websales/shopping/checkout', 'PUT', null, body, query, custom_headers)

                if(response.success && response.data) {

                    commit('setOrder', response.data.order)
                    commit('setReference', response.data.order.reference)
                    //await dispatch ('listOrderHistory')
                    await dispatch ('restoreCart');
                    commit('setAlert', {
                        open: true,
                        message: 'Producto añadido con exito',
                        variant: 'success',
                        text_color: 'white',
                        timeout: 3000
                    }, { root: true})
                }

                else if(!response.success && response.errors) {
                    console.log(response.errors)
                }

                else if(response.success && !response.data) {
                    console.log('Se ha perdido la conexión con la API')
                }

                
            }

            else {
                //create order
                const body = {
                    product: [{
                        code: new_product.code,
                        qty: new_product.qty,
                        total: new_product.total,
                        promo: false
                    }],
                    total: new_product.total,
                }


                const custom_headers = rootGetters.getHeaders;

                const response = await sendRequest('websales/shopping/checkout', 'POST', null, body, null, custom_headers)

                if(response.success && response.data) {


                    commit('setOrder', response.data.order)
                    commit('setReference', response.data.order.reference)
                    await dispatch('restoreCart');
                    commit('setAlert', {
                        open: true,
                        message: 'Producto añadido con exito',
                        variant: 'success',
                        text_color: 'white',
                        timeout: 3000
                    }, { root: true})
                }

                else if(!response.success && response.errors) {
                    console.log(response.errors)
                }

                else if(response.success && !response.data) {
                    console.log('Se ha perdido la conexión con la API')
                }

                
            }
 
        },
        removeFromShoppingCart: async({ commit, getters, rootGetters }, remove_product) => {
            const products = getters.getCartProducts.map((product) => {
                return {
                    code: product.code,
                    qty: product.qty,
                    total: formatAmount(Number(product.qty) * Number(product.price), 2),
                    promo: false,
                    name: product.name
                }
            })

            let index = products.findIndex( (product) => product.code == remove_product.code)
            console.log('index:', index)
            console.log('products:', products)
            console.log('remove:', remove_product)

            if(index < 0)  return

            if(index == 0 && products.length == 1) {
                commit('setCartProducts', [])
                commit('setAlert', {
                    open: true,
                    message: 'Producto eliminado con exito',
                    variant: 'success',
                    text_color: 'white',
                    timeout: 3000
                }, { root: true})
                return;
            }

            let new_products = products.filter((product) => product.code !== remove_product.code)

            let product_info = new_products.map( product => {
                return {
                    name: product.name || 'sin nombre',
                    code: product.code
                }
            })

            let total = 0;
            for(let product in new_products) {
                total = formatAmount(Number(total) + Number(products[product].total))
            }

            const body = {
                product: new_products,
                total: total
            }

            const query = {
                reference : getters.getReference
            }

            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('websales/shopping/checkout', 'PUT', null, body, query, custom_headers)

            if(response.success && response.data) {

                for(let product in response.data.order.summary.product) {
                    for(let info in product_info) {
                        if(product_info[info].code == response.data.order.summary.product[product].code ) {
                            response.data.order.summary.product[product].name = product_info[info].name
                        }
                    }
                }


                commit('setOrder', response.data.order)
                commit('setReference', response.data.order.reference)
                commit('setCartProducts', response.data.order.summary.product)
                commit('setAlert', {
                    open: true,
                    message: 'Producto eliminado con exito',
                    variant: 'success',
                    text_color: 'white',
                    timeout: 3000
                }, { root: true})
            }

            else if(!response.success && response.errors) {
                console.log(response.errors)
            }

            else if(response.success && !response.data) {
                console.log('Se ha perdido la conexión con la API')
            }
        },
        addFavoritesToCart: async ({commit, getters, rootGetters}, fav_products) => {

            let products = getters.getCartProducts.map((product) => {
                return {
                    code: product.code,
                    qty: product.qty,
                    total: formatAmount(Number(product.qty) * Number(product.price), 2),
                    promo: false,
                    name: product.name
                }
            })

            for(let fav in fav_products) {
                let index = products.findIndex(( product) => product.code == fav_products[fav].code)

                if(index >= 0) {

                    products[index].qty = products[index].qty + fav_products[fav].qty
                    console.log('qty:',products[index].qty )
                }

                else {
                    products.push(fav_products[fav])
                }
            }

            let product_info = products.map( product => {
                return {
                    name: product.name || 'sin nombre',
                    code: product.code
                }
            })
            

            let total = 0;
            for(let product in products) {
                total = formatAmount(Number(total) + Number(products[product].total))
            }

            const body = {
                product: products,
                total: total
            }

            const query = {
                reference : getters.getReference
            }
                
            console.log('info_products', product_info)

            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('websales/shopping/checkout', 'PUT', null, body, query, custom_headers)

            if(response.success && response.data) {

                for(let product in response.data.order.summary.product) {
                    for(let info in product_info) {
                        if(product_info[info].code == response.data.order.summary.product[product].code ) {
                            response.data.order.summary.product[product].name = product_info[info].name
                        }
                    }
                }

                commit('setOrder', response.data.order)
                commit('setReference', response.data.order.reference)
                commit('setCartProducts', response.data.order.summary.product)
                commit('setAlert', {
                    open: true,
                    message: 'Producto(s) añadido(s) al carrito.',
                    variant: 'success',
                    text_color: 'white',
                    timeout: 3000
                }, { root: true})
            }

            else if(!response.success && response.errors) {
                console.log(response.errors)
            }

            else if(response.success && !response.data) {
                console.log('Se ha perdido la conexión con la API')
            }
        },
        markFavorite: async ({ commit, rootGetters}, id) => {
            const custom_headers = rootGetters.getHeaders

            const body = {
                product: id
            }

            const response = await sendRequest('websales/product/bookmark', 'POST', null, body, null, custom_headers )
            
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Producto añadido a favoritos con éxito.',
                    variant: 'success',
                    text_color: 'white',
                    timeout: 3000
                }, { root: true})

            }

            else if(!response.success && response.errors) {
                console.log('errors:', response.errors)
            }

            else if(response.success && !response.data) {
                console.log('Se perdio la conexíón con la API')
            }
        },
        setOrderAddress: async ({commit, rootGetters, getters}, address) => {
            const custom_headers = rootGetters.getHeaders

            const reference = getters.getReference

            const response = await sendRequest('websales/shopping/contact', 'POST', null, address, {reference: reference}, custom_headers)

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Dirección actualizada con éxito.',
                    variant: 'success',
                    text_color: 'white',
                    timeout: 3000
                }, { root: true})
            }

            else if(!response.success && response.errors) {
                console.log('Errors:', response.errors)
            }

            else if(response.success && response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        getOrderAddress: async ({commit, rootGetters, getters}, address) => {
            const custom_headers = rootGetters.getHeaders

            const reference = getters.getReference

            const response = await sendRequest('websales/shopping/address', 'GET', null, null, {reference: reference}, custom_headers)

            console.log('RESPONSE:', response)
            if(response.success && response.data) {
                
            }

            else if(!response.success && response.errors) {
                console.log('Errors:', response.errors)
            }

            else if(response.success && response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        getHolder: async ({commit, rootGetters, getters}) => {
            const custom_headers = rootGetters.getHeaders

            const reference = getters.getReference

            const response = await sendRequest('websales/shopping/holder', 'GET', null, null, {reference: reference}, custom_headers)

            console.log('RESPONSE:', response)
            if(response.success && response.data) {
                
            }

            else if(!response.success && response.errors) {
                console.log('Errors:', response.errors)
            }

            else if(response.success && response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        paymentInit: async ({ commit, dispatch, rootGetters, getters}) => {

            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('websales/shopping/init', 'GET', null, null, null, custom_headers)

            if(response.success && response.data) {

            }

            else if(!response.success && response.errors ) {
                console.log(response.errors)
            }

            else if(response.success && response.data) {
                console.log('Se perdio la conexión con API.')
            }
        },
        cardGeneration: async ({ commit, dispatch, rootGetters, getters}) => {
            
            const custom_headers = rootGetters.getHeaders

            let query = {
                reference: getters.getReference
            }

            let body = {
                payment: 'card',
                card: {
                    holder: 'websales',
                    number: 1234
                }
            }

            const response = await sendRequest('websales/shopping/generate', 'POST', null, body, query, custom_headers )

            if(response.success && response.data) {
                commit('setReference', null)
                commit('setOrder', null)
                commit('setCartProducts', [])
                commit('setAlert', {
                    open: true,
                    message: 'Orden creada con éxito..',
                    variant: 'success',
                    text_color: 'white',
                    timeout: 3000
                }, { root: true})
            }

            else if(!response.success && response.errors) {
                let messages = []

                for(let i in response.errors) {
                    switch(response.errors[i].code) {
                        case 'wrong_order_unrecognized':
                            messages.push('Orden no reconocida')
                            break;
                        case 'wrong_order_inactive':
                            messages.push('Orden inactiva')
                            break;
                        case 'wrong_update_orders':
                            messages.push('Problema al actualizar la orden')
                            break;
                        case 'wrong_begin_transaction':
                            messages.push('Ah ocurrido un problema al procesar la información.')
                            break;
                        case 'wrong_order_productos':
                            messages.push('Ah ocurrido un problema con los productos de la orden.')
                            break;
                        case 'wrong_create_orders_payment':
                            messages.push('Error al crear el procesamiento del pago.')
                            break;
                        case 'wrong_create_puntos_payment':
                            messages.push('Error al procesar el pago con puntos.')
                            break;
                        case 'wrong_folio':
                            messages.push('Problema al crear el folio.')
                            break;
                        case 'wrong_upload_session_orders':
                            messages.push('Problema al guardar la orden.')
                            break;
                        case 'wrong_commit':
                            messages.push('Problema al procesar la información.')
                            break;
                        default:
                            messages.push('Un error ha ocurrido, intente más tarde')
                            break;
                    }
                }


                commit('setAlert', {
                    open: true,
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                    timeout: 3000
                }, { root: true})
            }

            else if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        processPayment: async({ commit, dispatch, rootGetters}, payment) => {

            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('websales/payment/card/token', 'POST', null, payment, null, custom_headers) 

            if(response.success && response.data) {
                commit('setPaymentUrl', response.data.payment)
            }

            else if(!response.success && response.errors) {
                console.log(response.errors)
            }

            else if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        restoreCart: async({ commit, dispatch, rootGetters, getters}) => {
            let order = getters.getOrder ?? [];
            
            //console.log(order)
            //LRNWQ9YNRRB35SYVBW27
            //ZZT11NNQDFLGXHS36HWQ

            const custom_headers = rootGetters.getHeaders;

            let summary_products = [];
            let product_info = [];
            const response = await sendRequest('websales/shopping/summary', 'GET', null, null, { reference: order.reference}, custom_headers);

            if(response.success && response.data) {
                summary_products = [...response.data.order.summary.product]
                commit('setReference', response.data.order.reference)
                for await(let product of response.data.order.summary.product) {
                    await dispatch('viewProduct', { code: product.code})
                    let product_retrieved = getters.getProduct;

                    if(product_retrieved !== null) {
                        product_info.push({ product_name: product_retrieved.product_name, code: product_retrieved.product_code})
                    }
                    
                }
            }
            
            // console.log('SUMMARY:', summary_products);
            // console.log('INFO:',  product_info)

            for(let i in summary_products) {
                for(let j in product_info) {
                    if(summary_products[i].code == product_info[j].code) {
                        summary_products[i].name = product_info[j].product_name
                    }
                }
            }

            console.log('Cart:', summary_products);
            commit('setCartProducts', summary_products);
        },
        processSale: async({commit, dispatch, rootGetters, getters}, data) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('websales/payment/card/charge','POST', null, data.body, data.query, custom_headers)

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Orden encontrada con éxito.',
                    variant: 'success',
                    text_color: 'white',
                    timeout: 3000
                }, { root: true})
                commit('setTokenFound', true)
            }
            else if(!response.success && response.errors) {
                commit('setAlert', {
                    open: true,
                    message: 'Ha ocurrido un error intente más tarde.',
                    variant: 'danger',
                    text_color: 'white',
                    timeout: 3000
                }, { root: true})
                commit('setTokenFound', false)
            }
        },
        generateOrder: async({commit, dispatch, rootGetters, getters}, data) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('websales/shopping/generate','POST', null, data.body, data.query, custom_headers)

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Orden generada con éxito.',
                    variant: 'success',
                    text_color: 'white',
                    timeout: 3000
                }, { root: true})
                commit('setReference', null)
                commit('setCartProducts', [])
                commit('setOrder', null)
                
            }
            else if(!response.success && response.errors) {
                commit('setAlert', {
                    open: true,
                    message: 'Ha ocurrido un error intete más tarde.',
                    variant: 'danger',
                    text_color: 'white',
                    timeout: 3000
                }, { root: true})
                
            }
        }
    }
}
