<template>
    <div id="default-template" class="container-fluid p-0 m-0">
        <ws-header/>
        <slot></slot>
        <ws-footer/>
    </div>

    <ws-modal  centered v-if="has_invalid_session">
        <div class="row bg-white rounded p-5">
            <div class="col-12 dmsans-bold">
                <p style="font-size: 18px;">
                    La sessión ha terminado, por favor inicie otra.
                </p>
            </div>

            <div class="col-12">
                <button @click.stop="refreshSite"
                    class="btn btn-primary btn-pill"
                    type="button">
                    Aceptar
                </button>
            </div>
        </div>
    </ws-modal>
    

</template>

<script setup>

    import { computed } from 'vue'
    import { useStore } from'vuex'
    import { useRouter } from 'vue-router'

    //hooks
    const store = useStore()
    const router = useRouter()

    //variables
    const has_invalid_session = computed(() => (store.getters['getExpiredSession']))

    //methods
    const refreshSite = () => {
        sessionStorage.removeItem('wiks@session')
        router.push({ name: 'home'})
        store.commit('setExpiredSession', false)
        store.commit('setLogin', false)
        store.commit('setSession', null)
    }

</script>

<style lang="scss" scoped>
    #default-template {
        min-height: 100vh;
    }
</style>
