export default [
    {
        path: '/politicas-de-privacidad',
        name: 'privacy',
        meta: {
            layout: 'default'
        },
        component: () => import('VIEWS/Privacy.vue')
    }
    
]