<template>
    <div class="offer_card px-2 m-0 py-4 border border-5 cart_hover" :class="{ 'bg-light-gray': data.stock >= 1, 'bg-opacity-02': data.stock == 0 }" style="border-radius: 50px 0 50px 0 ;">

        <img @click.stop="markFavorite"
            class="icon icon_hover" :src="heart_fill"  alt="" v-if="data.bookmark">

        <img @click.stop="markFavorite"
            class="icon icon_hover" :src="heart" alt="" v-else>

        <img class="offer_image" :src="data.picture || pl_img" alt="">

        <span class="d-block my-1" style="text-shadow: 0px 2px 2px #c4c4c4;">
            {{ data.product_name }}
        </span>
        <span class="d-block dmsans-black my-3">
            $ {{ formatAmount(data.price,2) }}
        </span>

        <span class="d-block opensans-bold" v-if="data.stock !== undefined && data.stock >= 1">
            Cantidad
        </span>
        <div class="d-flex my-2 flex-center bg-white rounded p-2" v-if="data.stock !== undefined && data.stock >= 1">
            <button @click.stop="qty--"
                :disabled="qty == 1"
                class="btn border-0 px-2"
                style="background-color: #ececec;"
                type="button">
                <img :src="less" alt="">
            </button>
            
            <span class="mx-3" style="font-size: 18px;">
                {{ qty }}
            </span>

            <button @click.stop="qty++"
                class="btn border-0 px-2"
                style="background-color: #ececec;"
                type="button">
                <img :src="add_icon" alt="">
            </button>
        </div>

        <button @click.stop="sendToCart"
            class="btn btn-primary btn-pill buttonshine my-1"
            type="button" v-if="data.stock !== undefined && data.stock >= 30">
                Añadir al carrito
        </button>
        <span v-else class="text-white py-3 px-3 badge rounded-pill bg-danger">
            Temporalmente agotado
        </span>
     </div>
</template>

<script setup>
    const pl_img = require('ASSETS/placeholder')
    const heart = require('ASSETS/icons/heart')
    const heart_fill = require('ASSETS/icons/heart_fill')
    const add_icon = require('ASSETS/icons/add')
    const less = require('ASSETS/icons/minus')

    import { useStore } from 'vuex';
    import { formatAmount } from 'COMPOSABLES/formatAmount'
    import { ref } from 'vue';

    const store = useStore()

    const props = defineProps({
        data: {
            type: Object,
            required: false,
            default: () => ({ title: 'default_title', price: 'default_price', img: require('ASSETS/placeholder')})
        }
    })

    //variables
    const qty = ref(1)

    const emits = defineEmits(['mark:favorite'])


    const sendToCart = () => {
        store.dispatch('sales/addToShoppingCart', {code: props.data.product_code, qty: qty.value, total: formatAmount(props.data.price, 2), promo: false, name: props.data.product_name})
        qty.value = 1;
    }

    const markFavorite = () => {
        emits('mark:favorite', props.data.product_code)
    }

</script>
