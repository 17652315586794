<template>
    
    <div class="row p-0 m-0 justify-content-between position-sticky w-100" :class="{ 'bg-primary shadow_nav': has_session }" style="height: 85px; z-index: 100; top: 0;" >

        <div class="col-xxl-2 col-1 py-2 d-flex justify-content-center position-relative">
            <img class="d-none d-lg-inline-block"
                style="width: auto; height: 70px; object-fit: scale-down; cursor: pointer;" :src="wiks_logo" alt="logo wiks" width="261" height="199" loading="lazy" @click.stop="router.push({ name: has_session ? 'post-home' : 'home'  })">

            <button @click.stop="show_burger_menu = !show_burger_menu" v-if="has_session"
                class="btn d-lg-none"
                type="button">
                <img :src="burger" alt="">
            </button>

            <div class="position-absolute bg-primary dmsans-semibold" v-if="show_burger_menu"
                style="top: 100%; left: 0; width: 200px;">
                <ul class="list-unstyled" @mouseleave="show_burger_menu = false">
                    <template v-for="(option, index) in submenu" :key="`option-${index}`">

                        <li @click.stop="changeCategory(option)" class="py-3 text_hover hav_text" style="font-size: 18px;" v-if="!option.submenu">
                            <img :src="option.icon" alt="" style="max-width: 50px">
                            {{ option.name}}
                        </li>

                    </template>
                </ul>
            </div>
        </div>
<!------------------------------------------------->
        <div class="col-7 col-xxl-6 d-none d-lg-flex flex-column dmsans-semibold mt-2 justify-content-between" v-if="has_session">
        <ws-input
            _id="s_i"
            class="search_input_h w-100"
            placeholder="Buscar.."
            type="text">
        </ws-input>
            <ul class="list-unstyled d-flex align-items-center justify-content-between w-100 my-1">
                <template v-for="(option, index) in submenu" :key="`optindex-${index}`">
                    <li @click.stop="changeCategory(option)"

                        class="text_hover hav_text">
                        <img :src="option.icon" alt="" style="max-width: 100%">
                        {{ option.name }}
                    </li>
                </template>
            </ul>
        </div>

        <div class="col-10 col-md-9 col-lg-4 px-0 mx-0 d-flex justify-content-center">

            <div class="row p-0 m-0 align-items-center justify-content-evenly w-100">

                <div class="col-md-6 col-sm-4 col-2 p-0 m-0 d-flex justify-content-center text-white">
                    <img :src="wp_icon" alt="" class="me-1 icon_hover" v-if="has_session">
                    <div class="dmsans-bold d-none d-sm-block" style="font-size: 1rem;" v-if="has_session">
                        <a class="text-white text-decoration-none" style="cursor: pointer;" href="tel:+525536776619">55-3677-6619 </a>
                    <br>
                        <div class="dmsans-regular" style="font-size:0.5rem;">
                            Atención a clientes por whatsapp
                        </div>
                    </div>
                    
                </div>
                
                <div class="col-md-6 col-sm-8 col-10 p-0 m-0 d-flex align-items-center justify-content-end position-relative h-100 w-100" v-if="!has_session">
                    <button @click.stop="router.push({ path: '/login'})"
                        class="btn btn-danger btn-pill img_hover text-white ms-2 px-md-5 px-sm-4"
                        type="button"
                        style="">
                        Registrate
                    </button>
                    <div class="bg-primary mx-2 h-75" style="width: 2px;"></div>
                    <button @click.stop="show_login = !show_login"
                        class="btn btn-primary btn-pill img_hover me-2 px-md-5 px-sm-4"
                        type="button"
                        style="">
                        Inicia Sesión
                    </button>
                    
                    <div class="row position-absolute bg-white border border-1 border-sucess" v-if="show_login"
                        style="top: 100%; right: 5%; width: 260px;">

                        <div class="col-12 p-0 m-0" v-if="!is_logging_process">
                            <div class="row p-0 m-0">

                                <div class="text-start col-7">
                                    <span class="text-dark dmsans-semibold" style="font-size: 20px;">
                                        Inicia Sesión
                                    </span>
                                </div>
                                <div class="col-4 d-flex align-items-end p-0 m-0 pb-1">
                                    <span class="text-start dmsans-regular" style="font-size:11px;">
                                        Crea una cuenta
                                    </span>
                                </div>

                                <div class="col-12 py-2">
                                    <ws-input v-model="state.username"
                                        _id="user_i"
                                        class="default_input"
                                        label="Nombre de usuario o email"
                                        placeholder="Usuario"
                                        type="text">
                                    </ws-input>
                                </div>

                                <div class="col-12 py-2">
                                    <ws-input v-model="state.password"
                                        _id="pwd_i"
                                        class="default_input"
                                        placeholder="password"
                                        label="Password"
                                        type="password">
                                    </ws-input>
                                </div>

                                <div class="col-12 py-2">
                                    <button @click.stop="login"
                                        class="btn btn-primary w-100 img_hover">
                                        Accede
                                    </button>
                                </div>

                                <div class="col-12 py-2 text-danger img_hover">
                                    <span>
                                        ¿Olvidaste tu Constranseña?
                                    </span>
                                </div>

                            </div>
                        </div>

                        <div class="col-12 p-0 m-0 py-3" v-else>
                            <div class="row p-0 m-0">
                                <div class="col-4">
                                    <img style="width: 80px; height: 80px; object-fit: scale-down;" :src="wiks_logo" />
                                </div>

                                <div class="col-8">
                                    <p>
                                        espere un momento...
                                    </p>
                                </div>

                                <div class="col-12">
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    
                </div>

                <div class="col-6 p-0 m-0 d-flex align-items-center justify-content-evenly h-100 position-relative" v-if="has_session">
  
                   <div class="mx-1" style="width: 2px; height: 40px; background-color: #ffffff;"></div>
                   <button class="btn p-0 icon_hover"
                        type="button">
                        <img :src="bell" alt="" style="max-height: 50px">
                   </button>
                   <div class="mx-1" style="width: 2px; height: 40px; background-color: white;"></div>
                   <button @click.stop="cart_modal = true" class="btn p-0 position-relative icon_hover"
                        type="button">
                        <img :src="cart" alt="" style="max-height: 35px">
                        <span class="position-absolute bg-danger text-white dmsans-regular" style="top: -40%; right: -40%; width: 25px; height: 25px; border-radius: 50%;" v-if="cart_products.length > 0">
                            {{ cart_products.length }}
                        </span>
                   </button>
                   <div class="mx-1" style="width: 2px; height: 35px; background-color: white;"></div>
                   <button  @click.stop="show_logout = !show_logout"
                        class="btn p-0 position-relative me-1 icon_hover"
                        type="button">
                        <img width="40"
                            :src="avatar" alt=""> 
                        <span class="position-absolute text-white" style="right: 0; top: 8px; left: 0; bottom: 0;"> 
                            {{ session.prefix }}
                        </span>
                   </button>

                   <div class="d-flex w-100 bg-light-gray position-absolute flex-column" v-if="show_logout"
                        @mouseleave="show_logout = false"
                        style="top: 100%; border-radius: 0px 0px 20px 20px;">
                        <div class="d-flex align-items-center justify-content-center border-bottom py-1 bg-primary">
                            <div class="btn p-0 position-relative me-1"
                                type="button">
                                <img width="30"
                                    :src="avatar" alt="">
                                <span class="position-absolute text-white" style="right: 0; top: 3px; left: 0; bottom: 0;"> 
                                    {{ session.prefix }}
                                </span>
                            </div>
                            <span class="text-white " style="font-size: 12px;">{{ session.firstName }} {{ session.lastName }}
                            </span>
                        </div>

                        <!--<span class="text-primary py-1 my-1" style="font-size: 12px;">Teléfono: <b>{{ session.phone }}</b>
                        </span>
                        <span class="text-primary py-1" style="font-size: 12px;">Correo: <b>{{ session.email }}</b>
                        </span>-->

                        <a class="dmsans-bold text-center mx-sm-4 mx-2 my-2 p-1 bg-primary text-white position-relative rounded" 
                            @click.stop="router.push({ path: '/products-points'})" 
                            style="font-size: 0.7rem; text-decoration: none; cursor: pointer;">
                            <img :src="start" class="mx-2 rounded-circle p-sm-2 position-absolute top-50 start-0 translate-middle bg-primary">
                            Puntos: {{ profile.Puntos }}
                        </a>

                        <div class="d-flex flex-column justify-content-center">
                            <a class="dmsans-bold text-start m-sm-2 m-1 text_orange"  @click.stop="store.commit('setCurrentTab', 1); router.push({ path: '/shopping-cart'})" style="font-size: 0.7rem; text-decoration: none; cursor: pointer;"><img :src="person" class="mx-sm-2 mx-1 rounded-circle p-1 img_menu_nav" >Perfíl</a>
                            <a class="dmsans-bold text-start m-sm-2 m-1 text_orange" @click.stop="store.commit('setCurrentTab', 2); router.push({ path: '/shopping-cart'})" style="font-size: 0.7rem; text-decoration: none; cursor: pointer;"><img :src="favorite" class="mx-sm-2 mx-1 rounded-circle p-1 img_menu_nav">Favoritos</a>
                            <a class="dmsans-bold text-start m-sm-2 m-1 text_orange" @click.stop="store.commit('setCurrentTab', 3); router.push({ path: '/shopping-cart'})" style="font-size: 0.7rem; text-decoration: none; cursor: pointer;"><img :src="history" class="mx-sm-2 mx-1 rounded-circle p-1 img_menu_nav" >Pedidos</a>
                            <a class="dmsans-bold text-start m-sm-2 m-1 text_orange" @click.stop="store.commit('setCurrentTab', 4); router.push({ path: '/shopping-cart'})" style="font-size: 0.7rem; text-decoration: none; cursor: pointer;"><img :src="start" class="mx-sm-2 mx-1 rounded-circle p-1 img_menu_nav">Canjes</a>
                            <a class="dmsans-bold text-start m-sm-2 m-1 text_orange" Target="_blank" href="https://api.whatsapp.com/send?phone=5215536776619&text=Me%20gustaría%20más%20información" style="font-size: 0.7rem; text-decoration: none; cursor: pointer;"><img :src="call" class="mx-sm-2 mx-1 rounded-circle p-1 img_menu_nav" >Atención a Clientes</a>
                        </div>

                        <button @click.stop="logout"
                            class="btn btn-primary btn-pill py-0 mx-3 my-1 img_hover"
                            type="button">
                            Cerrar Sesión
                        </button>
                   </div>
                </div>

            </div>
            
        </div>
    </div>
<!--------------------------------------------------------------------->
    <ws-modal max_width="470" v-if="cart_modal">
        <div class="row p-0 m-0 align-items-center px-4">
            <div class="col-6 p-0 py-2 text-start">
                <p class="m-0 dmsans-semibold">
                    LISTA DE COMPRAS
                </p>
            </div>
            <div class="col-6 p-0 d-flex justify-content-end">
                <button @click.stop="cart_modal = false;"
                    class="btn"
                    type="button">
                    cerrar x
                </button>
            </div>

            <template v-if="cart_products.length > 0">
                <div class="col-12 p-0 border border-1 mb-3" style="max-height: clamp(300px, 30vw ,350px); overflow-y: scroll">
                    <div class="row border-1 border-primary border-top border-bottom px-2 p-m-3 justify-content-between align-items-center dmsans-regular" v-for="(product, index) in cart_products" :key="`product-cart-index-${index}`">
                        <div class="col-12 d-flex align-items-center justify-content-center">
                            <img :src="`https://api.wiks-services.online/administrator/products/resources?id=${product.code}`" alt="" style="width: 50px; height: 50px; object-fit: scale-down;">
                            <div class="text-start w-75">
                                <p class="m-0 px-3">
                                    {{ product.name }}
                                </p>
                            </div>
                            <button @click.stop="removeFromCart(product)"
                                class="btn">
                                <img :src="trash" alt="" style="width: 25px; height: 25px;">
                            </button>
                        </div>
                        
                        <div class="col-3 dmsans-bold p-0">
                            <span style="font-size: clamp(10px, 4vw, 16px);">
                                Cantidad
                            </span>
                        </div>
                        <div class="col-3 dmsans-bold p-0">
                            <span>
                                Precio 
                            </span>
                        </div>
                        <div class="col-3 dmsans-bold p-0">
                            <span>
                                Descuento
                            </span>
                        </div>
                        <div class="col-3 dmsans-bold p-0">
                            <span>
                                Total
                            </span>
                        </div>

                        <div class="col-3 d-flex justify-content-center px-0 align-items-center" v-if="!is_updating_cart">
                            <button @click.stop="() => {product.qty--; updateCartProduct(product)}"
                                :disabled="product.qty == 1"
                                class="btn border-0 p-0"
                                type="button">
                                <img width="15"
                                    style="transform: rotate(90deg)" 
                                    :src="arrow" alt="">
                            </button>
                            <span class="mx-2 d-inline-block">
                                {{ product.qty}}
                            </span>
                            <button @click.stop="() => {product.qty++; updateCartProduct(product)}"
                                class="btn border-0 p-0"
                                type="button">
                                <img width="15"
                                    style="transform: rotate(-90deg)" :src="arrow">
                            </button>
                        </div>

                        <div class="col-12 d-flex justify-content-between px-0 align-items-center" v-if="is_updating_cart">
                            <div class="spinner-border spinner-border-sm text-dark mx-5" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>


                        <div class="col-3 p-0">
                            <span>
                               {{ formatAmount(Number(product.unity)) }} 
                            </span>
                        </div>
                        <div class="col-3 p-0">
                            <span>
                                {{ formatAmount(product.discount) }}
                            </span>
                        </div>
                        <div class="col-3 p-0">
                            <span>
                               $ {{ formatAmount(Number(product.total) - (Number(product.qty * product.discount))) }}
                            </span>
                        </div>
                    </div>
                </div>
            </template>

            <div class="col-12 py-3" v-else>
                <p>
                    No has añadido productos a tu carrito.
                </p>
            </div>

            <div class="col-12 p-2 bg-light-gray" style="border-radius: 10px;" v-if="cart_products.length > 0">
                <div class="row p-0 m-0">

                    <div class="col-12 text-start dmsans-semibold">
                        <p class="m-0">
                            Detalle del pedido
                        </p>
                    </div>

                    <div class="col-6 text-start">
                        <p class="m-0">
                            Total de Compra:
                        </p>
                    </div>
                    <div class="col-6 text-end">
                        <p>
                            MXN {{ formatAmount(subtotal) }}
                        </p>
                    </div>

                    <div class="col-6 text-start dmsans-semibold">
                        <p class="m-0">
                            Descuentos
                        </p>
                    </div>
                    <div class="col-6 text-end">
                        <p class="m-0">
                            MXN {{ formatAmount(discounts) }}
                        </p>
                    </div>

                    <div class="col-6 text-start">
                        <p class="m-0">
                            TOTAL DE VENTA 
                        </p>
                    </div>
                    <div class="col-6 text-end text-success dmsans-semibold">
                        <p class="m-0" style="font-size: 18px;">
                            MXN {{ formatAmount(total) }}
                        </p>
                    </div>
                </div>
            </div>
            
            <div class="col-12 py-5 p-0" v-if="cart_products.length > 0">
                <button @click.stop="router.push({ name: 'shopping-cart'}); cart_modal = false;"
                    class="btn btn-dark btn-lg w-100 img_hover"
                    type="button">
                    Finalizar compra
                </button>
            </div>
        </div>
    </ws-modal>
</template>

<script setup>
    const wiks_logo = require('ASSETS/wiks_logo')
    const burger = require('ASSETS/icons/burger')
    const bell = require('ASSETS/icons/bell_icon')
    const cart = require('ASSETS/icons/shopcart')
    const avatar = require('ASSETS/icons/avatar')
    const trash = require('ASSETS/icons/trash_icon')
    const wp_icon = require('ASSETS/icons/whatsapp')
    const arrow = require('ASSETS/icons/arrow.png')

    const beer = require('ASSETS/icons/beer.png')
    const grocery = require('ASSETS/icons/grocery.png')
    const soda = require('ASSETS/icons/soda.png')
    const start = require('ASSETS/icons/start.png')
    const water = require('ASSETS/icons/water.png')
    const wine = require('ASSETS/icons/wine.png')

    const call = require('ASSETS/icons/call.png')
    const favorite = require('ASSETS/icons/favorite.png')
    const history = require('ASSETS/icons/history.png')
    const person = require('ASSETS/icons/person.svg')

    import { ref, computed, reactive, resolveComponent, onMounted } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';

    //hooks
    const store = useStore();
    const router = useRouter();

    //composables
    import {formatAmount } from 'COMPOSABLES/formatAmount';

    //variables
    const state = reactive({
        username: null,
        password: null
    })
    

    const menu = ref([
        {
            label: 'Cerveza',
            route: 'categories',
            img: 'ASSETS/icons/arrow.png',
            
        },
        {
            label: 'Refrescos',
            route: 'categories',
            img: 'ASSETS/icons/arrow.png',
        },
        {
            label: 'Vinos y Licores',
            route: 'categories',
            img: 'ASSETS/icons/arrow.png',
        },
        {
            label: 'Abarrotes',
            route: 'categories',
            img: 'ASSETS/icons/arrow.png',
        },
        {
            label: 'Agua y Hielos',
            route: 'categories',
            img: 'ASSETS/icons/arrow.png',
        },
        // {
        //     label: 'Promociones',
        //     route: 'offers'
        // },
        //{
        //    label: 'Familias',
        //    open: false,
        //    submenu: [
        //        {
        //            label: 'Nuestras Marcas',
        //            route: 'brands',
        //        }
        //    ]
        //},
        //{
        //    label: 'Carrito',
        //    route: 'shopping-cart'
        //},
        {
           label: 'Wiks Puntos',
           open: false,
           submenu : [
               {
                   label: 'Productos',
                   route: 'points-products'
               },
               {
                   label: 'Centro de canje',
                   route: 'points-checkout'
               }
           ]
        }
    ])

    const show_login = ref(false);
    const show_logout = ref(false);
    const show_burger_menu = ref(false);
    const cart_modal = ref(false);
    const is_logging_process = ref(false);
    const is_updating_cart = ref(false);

    //computed
    //const point_history = computed(() => store.getters['program/getRedeems']);
    //const get_session = computed(() => store.getters['getSession']);
    //const point_detail = computed(() => store.getters['program/getRedeem']);
    const families = computed(() => (store.getters['sales/getFamilies']))
    const submenu = computed(() => {
    const icons = [beer, soda, wine, water, grocery,  start];

    let mn_menu = families.value.map((item, index) => {
        return {
            id: item.id,
            name: item.name,
            route: 'categories',
            icon: icons[index] || defaultIcon 
        };
    });
    
    // Agrega el ícono específico para 'wiks puntos'
    mn_menu = [...mn_menu, {id: 0, name: 'Wiks Puntos', route: 'points-products', icon: start}];

    return mn_menu;
    });

    const has_session  = computed(() => (store.getters['getLogin']));

    const session = computed(() => (store.getters['getSession']))
    const profile = computed(() => (store.getters['program/getProfiles']))
    //console.log(profile.value, "aqui")

    const order = computed(() => (store.getters['sales/getOrder']));
    const cart_products = computed(() => (store.getters['sales/getCartProducts']));

    const subtotal = computed(() => {
        let products = cart_products.value
        let subtotal = 0

        for(let product in products) {
            let raw_total = formatAmount(Number(products[product].unity) * products[product].qty, 2)
            subtotal = formatAmount(Number(subtotal) + Number(raw_total), 2)
        }
        
        return subtotal
    })


    const discounts = computed(() => {
        let products = cart_products.value
        let discount = 0

        for(let index in products) {
            discount += products[index].discount * products[index].qty;
        }
        
        return discount
    })

    const total = computed(() => {
        
        let total = formatAmount(subtotal.value - discounts.value);
        
        return total
    });

    const iva = computed(() => {
        let products = cart_products.value
        let iva = 0

        for(let product in products) {
            iva = formatAmount(Number(iva) + Number(products[product].iva))
        }
        
        return iva
    });
    

    //methods
    const login = async () => {
        is_logging_process.value = true

        const body = {
            username: state.username,
            password: state.password
        }

        await store.dispatch('login', body)
        console.log(has_session)

        if(!has_session.value) {
            is_logging_process.value = false
        } else {
            is_logging_process.value = false
            state.username = null
            state.password = null
            show_login.value = false
            router.push({name:"post-home"})
        }
        
    }

    const logout = () => {
        store.commit('setSession', null)
        store.commit('sales/setReference', null)
        store.commit('setLogin', false)
        sessionStorage.clear()
        show_logout.value = false
        router.push({name:"home"})
        
    }

    const changeCategory = async (family) => {
          await store.dispatch('sales/listCategories', {family_id: family.id})
          //console.log(family.name)
          router.push({name: family.route})
          show_burger_menu.value = false
    }


    const removeFromCart = async (product) => {
        await store.dispatch('sales/removeFromShoppingCart', {code: product.code})
    }

    const updateCartProduct = async (product) => {
        is_updating_cart.value = true
        await store.dispatch('sales/addToShoppingCart', {code: product.code, qty: product.qty, total: formatAmount(product.unity * product.qty, 2), promo: false, name: product.name})
        is_updating_cart.value = false
    }
    /*onMounted(async() => {
        try {

        //contact info

        //list of orders
        await store.dispatch('program/listProfiles');
        }
        catch(e) {
        console.error(e)
        }
     })*/
    // onMounted(async() => {
    //     try {
    //         if(get_session.value !== null){
    //             await store.dispatch('program/listPointRedeems');
    //             console.log(point_history.value, "poinnnnnnnn")
    //             if(point_history.value !== null && point_history.value.length > 0)  {
    //             //     await store.dispatch('program/viewPointRedeem', { reference: point_history.value[0].Referencia });
    //             }
    //         }
    //         console.log(get_session.value , "este")
    //         loading.value = false
    //     } catch (e) {
    //         console.log(e)
    //     }
    // })
    
</script>



